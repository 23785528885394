var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import PropTypes from 'prop-types';
import Decades from './CenturyView/Decades.js';
import { tileGroupProps } from './shared/propTypes.js';
/**
 * Displays a given century.
 */
var CenturyView = function CenturyView(props) {
    function renderDecades() {
        return React.createElement(Decades, __assign({}, props));
    }
    return React.createElement("div", { className: "react-calendar__century-view" }, renderDecades());
};
CenturyView.propTypes = __assign(__assign({}, tileGroupProps), { showNeighboringCentury: PropTypes.bool });
export default CenturyView;
